"use client";

import { revalidateSession } from "@/actions/session";
import { handleRedirectResult } from "@/lib/firebase/auth";
import { googleAuth } from "@llamacloud/auth/client";
import { assertExists, cn } from "@llamacloud/shared/utils";
import { Button } from "@llamaindex/component/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@llamaindex/component/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@llamaindex/component/ui/dialog";
import { Input } from "@llamaindex/component/ui/input";
import { Label } from "@llamaindex/component/ui/label";
import { useQueryClient } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendEmailVerification, sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailAndPassword } from "firebase/auth";
import { Mail } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { toast } from "sonner";
export function SignIn() {
  const [signWithEmail, setSignWithEmail] = useState(false);
  const [resetEmail, setResetEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailLinkSignIn, setEmailLinkSignIn] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);
  const {
    replace,
    prefetch
  } = useRouter();
  const queryClient = useQueryClient();
  useEffect(() => {
    prefetch("/login/callback");
  }, [prefetch]);
  const handlePasswordReset = async () => {
    assertExists(googleAuth, "googleAuth is not defined");
    toast.promise(sendPasswordResetEmail(googleAuth, email), {
      loading: "Sending Reset Email...",
      success: "Reset Email Sent",
      error: "Error sending reset email"
    });
  };
  const handleEmailLinkSignIn = async () => {
    assertExists(googleAuth, "googleAuth is not defined");
    const signInMethods = await fetchSignInMethodsForEmail(googleAuth, email);
    if (signInMethods.length === 0) {
      setShowRegisterDialog(true);
      return;
    }
    const actionCodeSettings = {
      url: `${window.location.origin}/login/callback?email=${encodeURIComponent(email)}`,
      handleCodeInApp: true
    };
    toast.promise(sendSignInLinkToEmail(googleAuth, email, actionCodeSettings), {
      loading: "Sending Sign-In Link...",
      success: "Sign-in link sent to your email!",
      error: "Error sending sign-in link"
    });
  };
  const handleRegister = async () => {
    assertExists(googleAuth, "googleAuth is not defined");
    try {
      const userCredential = await createUserWithEmailAndPassword(googleAuth, email, password);
      toast.success("Account created successfully!");
      sendEmailVerification(userCredential.user, {
        url: `${window.location.origin}/login/callback?email=${encodeURIComponent(email)}`
      }).then(() => {
        toast.success("Verification email sent, please verify your email");
      });
      setShowRegisterDialog(false);
      const shouldRedirect = await handleRedirectResult(userCredential);
      if (shouldRedirect) {
        await Promise.all([queryClient.invalidateQueries(), revalidateSession()]);
        replace("/");
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === "auth/email-already-in-use") {
          toast.error("An account with this email already exists");
        } else {
          toast.error(error.message);
        }
      }
    }
  };
  return <>
      <Card className="max-w-md bg-white p-4" data-sentry-element="Card" data-sentry-source-file="login.tsx">
        <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="login.tsx">
          <div className="flex justify-center">
            <Image src="/assets/rounded_corners_logo.png" alt="LlamaIndex" width={80} height={80} className="h-12 w-auto self-start rounded-lg" data-sentry-element="Image" data-sentry-source-file="login.tsx" />
          </div>
          <CardTitle className="text-center text-lg md:text-xl" data-sentry-element="CardTitle" data-sentry-source-file="login.tsx">
            Welcome to LlamaCloud
          </CardTitle>
          <CardDescription className="text-center text-xs md:text-sm" data-sentry-element="CardDescription" data-sentry-source-file="login.tsx">
            Sign in or create an account to get started.
          </CardDescription>
        </CardHeader>
        <CardContent className="px-16" data-sentry-element="CardContent" data-sentry-source-file="login.tsx">
          <div className="grid gap-4">
            {resetEmail ? <>
                <div className="grid gap-2">
                  <Label htmlFor="reset-email">Enter your email</Label>
                  <Input id="reset-email" type="email" placeholder="m@example.com" required onChange={e => {
                setEmail(e.target.value);
              }} value={email} />
                </div>

                <Button type="button" className="w-full" onClick={handlePasswordReset}>
                  Send Reset Email
                </Button>

                <Button variant="outline" className="w-full" onClick={() => setResetEmail(false)}>
                  Cancel
                </Button>
              </> : signWithEmail ? <>
                <div className="grid gap-2">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" type="email" placeholder="m@example.com" required onChange={e => {
                setEmail(e.target.value);
              }} value={email} />
                </div>
                <div className="grid gap-2">
                  <div className="flex items-center justify-between">
                    <Label htmlFor="password">Password</Label>

                    <Label className="cursor-pointer text-xs text-muted-foreground hover:underline" onClick={() => setResetEmail(true)}>
                      Forgot Password?
                    </Label>
                  </div>

                  <Input id="password" type="password" placeholder="password" autoComplete="password" value={password} onChange={e => setPassword(e.target.value)} />
                </div>

                <Button type="submit" className="w-full" onClick={async () => {
              assertExists(googleAuth, "googleAuth is not defined");
              try {
                const userCredential = await signInWithEmailAndPassword(googleAuth, email, password);
                toast.success("Logged in successfully");
                const shouldRedirect = await handleRedirectResult(userCredential);
                if (shouldRedirect) {
                  await Promise.all([queryClient.invalidateQueries(), revalidateSession()]);
                  replace("/");
                }
              } catch (error) {
                if (error instanceof FirebaseError && error.code === "auth/user-not-found") {
                  setShowRegisterDialog(true);
                } else {
                  toast.error(error instanceof Error ? error.message : "An error occurred");
                }
              }
            }}>
                  Login
                </Button>

                <Button variant="outline" className="w-full" onClick={() => setSignWithEmail(false)}>
                  Cancel
                </Button>
              </> : emailLinkSignIn ? <>
                <div className="grid gap-2">
                  <Label htmlFor="email">Enter your email</Label>
                  <Input id="email" type="email" placeholder="m@example.com" required onChange={e => {
                setEmail(e.target.value);
              }} value={email} />
                </div>

                <Button type="button" className="w-full" onClick={handleEmailLinkSignIn}>
                  Send Sign-In Link
                </Button>

                <div className="relative my-3">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      Or continue with
                    </span>
                  </div>
                </div>

                <div className="flex justify-center gap-2">
                  <Button variant="outline" onClick={() => setSignWithEmail(true)}>
                    Password
                  </Button>

                  <Button variant="ghost" onClick={() => setEmailLinkSignIn(false)}>
                    Cancel
                  </Button>
                </div>
              </> : <div className={cn("flex w-full items-center gap-2", "flex-col justify-between")}>
                <Link href="/login/callback?provider=google" className={cn("w-full")}>
                  <Button variant="outline" className={cn("w-full justify-start gap-2")}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em">
                      <title>Google</title>
                      <path d="M23 12.245c0-.905-.075-1.565-.236-2.25h-10.54v4.083h6.186c-.124 1.014-.797 2.542-2.294 3.569l-.021.136 3.332 2.53.23.022C21.779 18.417 23 15.593 23 12.245z" fill="#4285F4" />
                      <path d="M12.225 23c3.03 0 5.574-.978 7.433-2.665l-3.542-2.688c-.948.648-2.22 1.1-3.891 1.1a6.745 6.745 0 01-6.386-4.572l-.132.011-3.465 2.628-.045.124C4.043 20.531 7.835 23 12.225 23z" fill="#34A853" />
                      <path d="M5.84 14.175A6.65 6.65 0 015.463 12c0-.758.138-1.491.361-2.175l-.006-.147-3.508-2.67-.115.054A10.831 10.831 0 001 12c0 1.772.436 3.447 1.197 4.938l3.642-2.763z" fill="#FBBC05" />
                      <path d="M12.225 5.253c2.108 0 3.529.892 4.34 1.638l3.167-3.031C17.787 2.088 15.255 1 12.225 1 7.834 1 4.043 3.469 2.197 7.062l3.63 2.763a6.77 6.77 0 016.398-4.572z" fill="#EB4335" />
                    </svg>
                    Sign in with Google
                  </Button>
                </Link>
                <Link href="/login/callback?provider=github" className={cn("w-full")}>
                  <Button variant="outline" className={cn("w-full justify-start gap-2")}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"></path>
                    </svg>
                    Sign in with Github
                  </Button>
                </Link>
                <Link href="/login/callback?provider=microsoft" className={cn("w-full")}>
                  <Button variant="outline" className={cn("w-full justify-start gap-2")}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em">
                      <title>Azure</title>
                      <path d="M11.49 2H2v9.492h9.492V2h-.002z" fill="#F25022" />
                      <path d="M22 2h-9.492v9.492H22V2z" fill="#7FBA00" />
                      <path d="M11.49 12.508H2V22h9.492v-9.492h-.002z" fill="#00A4EF" />
                      <path d="M22 12.508h-9.492V22H22v-9.492z" fill="#FFB900" />
                    </svg>
                    Sign in with Microsoft
                  </Button>
                </Link>
                <Button variant="outline" className={cn("w-full justify-start gap-2")} onClick={() => {
              setEmailLinkSignIn(true);
            }}>
                  <Mail className="size-4" />
                  Sign in with Email
                </Button>
              </div>}
          </div>
        </CardContent>
        <CardFooter data-sentry-element="CardFooter" data-sentry-source-file="login.tsx">
          <div className="flex w-full justify-center border-t py-4">
            <p className="text-center text-xs text-neutral-500">
              By continuing, you are indicating that you accept our <br />
              <Link href="https://www.llamaindex.ai/files/terms-of-service.pdf" className="underline" target="_blank" data-sentry-element="Link" data-sentry-source-file="login.tsx">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link href="https://www.llamaindex.ai/files/privacy-notice.pdf" className="underline" target="_blank" data-sentry-element="Link" data-sentry-source-file="login.tsx">
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </CardFooter>
      </Card>

      <Dialog open={showRegisterDialog} onOpenChange={setShowRegisterDialog} data-sentry-element="Dialog" data-sentry-source-file="login.tsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="login.tsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="login.tsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="login.tsx">Create an Account</DialogTitle>
            <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="login.tsx">
              No account found with this email. Would you like to create one?
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="register-email" data-sentry-element="Label" data-sentry-source-file="login.tsx">Email</Label>
              <Input id="register-email" value={email} disabled className="bg-muted" data-sentry-element="Input" data-sentry-source-file="login.tsx" />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="register-password" data-sentry-element="Label" data-sentry-source-file="login.tsx">Password</Label>
              <Input id="register-password" type="password" value={password} onChange={e => setPassword(e.target.value)} data-sentry-element="Input" data-sentry-source-file="login.tsx" />
            </div>
          </div>
          <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="login.tsx">
            <Button variant="outline" onClick={() => setShowRegisterDialog(false)} data-sentry-element="Button" data-sentry-source-file="login.tsx">
              Cancel
            </Button>
            <Button onClick={handleRegister} data-sentry-element="Button" data-sentry-source-file="login.tsx">Create Account</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>;
}