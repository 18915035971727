"use client";

import { SignIn } from "@/components/section/login";
import { LoginLayout } from "@/layouts/login-layout";
import { googleAuth } from "@llamacloud/auth/client";
import { assertExists } from "@llamacloud/shared/utils";

// follow React.lazy
// eslint-disable-next-line no-restricted-syntax
export default function GoogleLoginPage() {
  assertExists(googleAuth, "googleAuth");
  return <LoginLayout data-sentry-element="LoginLayout" data-sentry-component="GoogleLoginPage" data-sentry-source-file="google.tsx">
      <SignIn data-sentry-element="SignIn" data-sentry-source-file="google.tsx" />
    </LoginLayout>;
}